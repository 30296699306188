
































































































































































import useSelectItems from "@/use/selectItems";
import { defineComponent, reactive, watch } from "@vue/composition-api";
import countriesList from "@/assets/data/countries.json";

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({
        participantState: {
          entity: "participant",
          fieldName: "state",
          values: [],
          operation: "in",
        },
      }),
    },
    degrees: {
      type: Array,
      required: false,
      default: () => [],
    },
    sponsors: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup(props, { root }) {
    const { participantStatusItems, salutationItems } = useSelectItems({
      root,
    });
    const state = reactive({
      language: root.$store.getters["language/getLanguage"],
    });

    const selectAllDegrees = () => {
      props.value.participantDegree.values = props.degrees.map(
        (el: any) => el.name
      );
    };

    const selectAllCountries = () => {
      props.value.participantCountry.values = countriesList.map(
        (el: any) => el.code
      );
    };

    const selectAllSponsors = () => {
      props.value.participantSponsor.values = props.sponsors.map(
        (el: any) => el.sponsor
      );
    };

    watch(
      () => props.value.participantDegree.operation,
      () => {
        if (props.value.participantDegree.operation === null) {
          props.value.participantDegree.operation = "in";
        }
      }
    );

    watch(
      () => props.value.participantCountry.operation,
      () => {
        if (props.value.participantCountry.operation === null) {
          props.value.participantCountry.operation = "in";
        }
      }
    );

    watch(
      () => props.value.participantSponsor.operation,
      () => {
        if (props.value.participantSponsor.operation === null) {
          props.value.participantSponsor.operation = "in";
        }
      }
    );

    return {
      state,
      participantStatusItems,
      salutationItems,
      selectAllDegrees,
      selectAllCountries,
      countriesList,
      selectAllSponsors,
    };
  },
});
